import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import localStyles from './SpotlightSearchIcon.module.pcss';
import useAiSearchChatUnreadCountInfo from '../../aichat/useAiSearchChatUnreadCountInfo';
import IconWithCountBadge from '@aurora/shared-client/components/common/IconWithCountBadge/IconWithCountBadge';

const SpotlightSearchOverlay = dynamic(
  () => import('../SpotlightSearchOverlay/SpotlightSearchOverlay'),
  {
    ssr: false
  }
);

/**
 * A search icon that opens a search modal.
 *
 * @author Adam Ayres
 */
const SpotlightSearchIcon: React.FC = () => {
  const cx = useClassNameMapper(localStyles);
  const [showOverlay, setShowOverlay] = useState(false);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.SPOTLIGHT_SEARCH_ICON
  );
  const { count: aiSearchChatUnreadCount } = useAiSearchChatUnreadCountInfo();
  const showAiSearchChatUnread = aiSearchChatUnreadCount > 0;

  if (textLoading) {
    return null;
  }

  return (
    <>
      <IconWithCountBadge
        wrapper={({ className: wrapperClassName, children: wrapperChildren }) => (
          <Button
            variant={ButtonVariant.UNSTYLED}
            className={cx(wrapperClassName, { 'lia-has-unread': showAiSearchChatUnread })}
            onClick={(): void => setShowOverlay(true)}
            data-testid="SpotlightSearchIcon.Icon"
            title={formatMessage('search')}
          >
            {wrapperChildren}
          </Button>
        )}
        icon={Icons.SearchIcon}
        badgeClassName={cx('lia-unread-badge')}
        badgeTitle={showAiSearchChatUnread ? aiSearchChatUnreadCount.toString() : null}
      />
      <SpotlightSearchOverlay showOverlay={showOverlay} setShowOverlay={setShowOverlay} />
    </>
  );
};

export default SpotlightSearchIcon;
