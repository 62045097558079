import type { MutableRefObject } from 'react';
import React, { useCallback, useContext, useEffect, useId, useRef, useState } from 'react';
import NavbarContext from '@aurora/shared-client/components/context/NavbarContext/NavbarContext';
import { offsetPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type { AutoCloseMetadata } from '../../../helpers/dropdown/useAutoclose';
import useAutoclose from '../../../helpers/dropdown/useAutoclose';
import NavbarDropdownToggle from '../NavbarDropdownToggle/NavbarDropdownToggle';
import type { MenuLinkItem } from '../NavbarLink/NavbarLink';
import NavbarLink, { MenuLinkCollapseType, MenuLinkType } from '../NavbarLink/NavbarLink';
import localStyles from './NavbarDropdown.module.pcss';

interface Props {
  /**
   * Array of menu link objects.
   */
  menuLinks: MenuLinkItem[];

  /**
   * Main link for collapse menu.
   */
  titleLink: MenuLinkItem;

  menuId: string;

  /**
   * Callback when menu is toggled.
   */
  onToggle?: (menuId: string, isOpen: boolean) => void;

  /**
   * Whether the menu is active. This is useful when there are
   * multiple menus managed by a common parent component that wants
   * to enforce only one is open at a time. If null, then this is
   * ignored.
   */
  activeMenu?: boolean | null;

  /**
   * This delay before opening the menu on mouse over.
   */
  openDelay?: number;

  /**
   * This delay before closing the menu on mouse over.
   */
  closeDelay?: number;

  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;

  /**
   * Element ref
   */
  ref?: MutableRefObject<HTMLDivElement>;
}

/**
 * Dropdown menu for use in the Navbar.
 *
 * @author Willi Hyde, Adam Ayres, Corey Aing
 */
const NavbarDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  menuLinks,
  titleLink,
  menuId,
  onToggle,
  activeMenu = null,
  openDelay = 70,
  closeDelay = 250,
  className,
  ref
}) => {
  const cx = useClassNameMapper(localStyles);
  const toggleId = useId();
  const uidSeed = useUIDSeed();
  const [show, setShow] = useState(false);
  const closeTimeout = useRef<ReturnType<typeof setTimeout>>();
  const openTimeout = useRef<ReturnType<typeof setTimeout>>();
  const containsMouse = useRef<boolean>(false);
  const openedFromTouch = useRef<boolean>(false);
  const { isClosingPermitted, getTrueSource } = useAutoclose('outside');
  const getDropdownMenuPosition = useContext(NavbarContext);

  function clearTimeouts() {
    if (openTimeout.current) {
      clearTimeout(openTimeout.current);
    }
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
  }

  const showMenu = useCallback(
    (isOpen: boolean) => {
      if (onToggle) {
        onToggle(menuId, isOpen);
      }
      if (!isOpen) {
        openedFromTouch.current = false;
        clearTimeouts();
      }
      setShow(isOpen);
    },
    [menuId, onToggle]
  );

  useEffect(() => {
    if (show && activeMenu === false) {
      showMenu(false);
    }
  }, [showMenu, activeMenu, show]);

  function handleToggle(
    isOpen: boolean,
    event: React.SyntheticEvent<Dropdown, Event>,
    metadata: AutoCloseMetadata
  ) {
    const source = getTrueSource(event, metadata);

    if (!isOpen && !isClosingPermitted(source)) {
      return;
    }

    showMenu(isOpen);
  }

  function handleMouseEnter(event: React.MouseEvent): void {
    event.preventDefault();
    clearTimeouts();
    containsMouse.current = true;
    openTimeout.current = setTimeout(() => {
      showMenu(true);
    }, openDelay);
  }

  function handleMouseLeave(event: React.MouseEvent): void {
    event.preventDefault();
    clearTimeouts();
    containsMouse.current = false;
    closeTimeout.current = setTimeout(() => {
      showMenu(false);
    }, closeDelay);
  }

  function handleTouchStart(event: React.TouchEvent): void {
    if (event.cancelable) {
      event.preventDefault();
    }

    clearTimeouts();
    openedFromTouch.current = true;
    showMenu(!show);
  }

  function handleTouchEnd(event: React.TouchEvent): void {
    event.preventDefault();
  }

  function handleClick(event: React.MouseEvent): void {
    event.stopPropagation();
  }

  const menuShown = show && activeMenu !== false;

  return (
    <Dropdown
      className={cx('lia-dropdown', className)}
      focusFirstItemOnShow={!containsMouse.current && !openedFromTouch.current}
      show={menuShown}
      data-menu-open={menuShown}
      onToggle={(isOpen, event, metadata) => handleToggle(isOpen, event, metadata)}
      ref={ref}
    >
      <Dropdown.Toggle
        as={NavbarDropdownToggle}
        titleLink={titleLink}
        enableAnchor={containsMouse.current}
        isOpen={show}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        id={toggleId}
      ></Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={offsetPopperConfig(0, getDropdownMenuPosition())}
        className={cx('lia-g-navbar-dropdown-menu')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="menu"
      >
        {titleLink.type !== MenuLinkType.COLLAPSE && openedFromTouch.current === true && (
          <>
            <NavbarLink collapseType={MenuLinkCollapseType.NONE} menuLink={titleLink} menuItem />
            <Dropdown.Divider className={cx('lia-g-navbar-dropdown-divider')} />
          </>
        )}
        {menuLinks.map(item => (
          <Dropdown.Item
            key={uidSeed(item.label)}
            as={NavbarLink}
            collapseType={MenuLinkCollapseType.ACCORDION}
            menuLink={item}
            menuItem
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarDropdown;
